import { noop } from 'lodash';
import { getModalUrl } from '@wix/yoshi-flow-editor/utils';
import { BaseModule } from './BaseModule';
import { dashify, removeDashes } from '../../utils/guid';
import settingsParams from '../../../../settingsParams';

type Props = {
  openDesktopChannelInfo(): void;
  openDesktopSubscription(): void;
};

export class ModalsModule extends BaseModule<Props> {
  private async openPopup(
    modalName: 'DesktopLightbox' | 'MobileLightbox',
    hash: string,
    onClose: (result: any) => void = noop,
  ) {
    const {
      controllerConfig: {
        wixCodeApi,
        compId,
        appParams: { instance },
      },
    } = this.params;

    const lightboxUrl = getModalUrl('WixVideo', modalName, instance);
    const url = new URL(lightboxUrl);

    url.hash = hash;
    url.searchParams.append('rootCompId', compId);
    url.searchParams.append(
      'rootPageId',
      wixCodeApi.site.currentPage?.id || '',
    );

    const result = await wixCodeApi.window.openPopup(
      url.href,
      {
        width: '100%' as any,
        height: '100%' as any,
        position: {
          origin: 'FIXED',
          placement: 'CENTER',
        },
        theme: 'BARE',
      },
      compId,
    );

    onClose(result && result.message && result.message.reason);
  }

  private async openDesktopLightbox(hash: string) {
    await this.openPopup('DesktopLightbox', hash);
  }

  private getChannelId = (): string => {
    return dashify(
      this.params.flowAPI.settings.get(settingsParams.channelId),
    ) as string;
  };

  private openDesktopChannelInfo = () => {
    const v2ChannelId = removeDashes(this.getChannelId());
    this.openDesktopLightbox(`#/channel/${v2ChannelId}/info`).catch();
  };

  private openDesktopSubscription = () => {
    const v2ChannelId = removeDashes(this.getChannelId());
    this.openDesktopLightbox(`#/payment/subscription/${v2ChannelId}`).catch();
  };

  async init(): Promise<Props> {
    return {
      openDesktopChannelInfo: this.openDesktopChannelInfo,
      openDesktopSubscription: this.openDesktopSubscription,
    };
  }
}
