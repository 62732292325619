import { ControllerParams } from '@wix/yoshi-flow-editor';
import { ModalsModule } from './ModalsModule';
import { PurchasesModule } from './PurchasesModule';
import { PricingPlansModule } from './PricingPlansModule';

export type ModulesProps = Awaited<ReturnType<typeof createModules>>;

export async function createModules(params: ControllerParams) {
  const modalsModule = new ModalsModule(params);
  const purchasesModule = new PurchasesModule(params);
  const pricingPlansModule = new PricingPlansModule(params);

  const [modals, purchases, pricingPlans] = await Promise.all([
    modalsModule.init(),
    purchasesModule.init(),
    pricingPlansModule.init(),
  ]);

  const props = {
    modals,
    purchases,
    pricingPlans,
  };

  const setProps = (newProps: Partial<ModulesProps>) =>
    params.controllerConfig.setProps(newProps);

  const setNewPropsTo =
    <
      TKey extends keyof typeof props,
      TValue extends Partial<typeof props[TKey]>,
    >(
      moduleKey: TKey,
    ) =>
    (newModuleProps: TValue) => {
      const currentModuleProps = props[moduleKey];

      props[moduleKey] = {
        ...currentModuleProps,
        ...newModuleProps,
      };

      setProps({
        [moduleKey]: props[moduleKey],
      });
    };

  modalsModule.onSetProps(setNewPropsTo('modals'));
  purchasesModule.onSetProps(setNewPropsTo('purchases'));
  pricingPlansModule.onSetProps(setNewPropsTo('pricingPlans'));

  return props;
}
