import { ControllerParams } from '@wix/yoshi-flow-editor';

export abstract class BaseModule<TModuleProps> {
  protected setControllerProps?: (props: Partial<TModuleProps>) => void;

  constructor(protected readonly params: ControllerParams) {}

  protected setProps(props: Partial<TModuleProps>) {
    if (this.setControllerProps) {
      this.setControllerProps(props);
    }
  }

  abstract init(): Promise<TModuleProps>;

  onSetProps(setControllerProps: (props: Partial<TModuleProps>) => void) {
    this.setControllerProps = setControllerProps;
  }
}
