import { BaseModule } from './BaseModule';
import { PricingPlansAPI } from '@wix/wix-vod-pricing-plans-api';
import { dashify } from '../../utils/guid';
import settingsParams from '../../../../settingsParams';

type Props = {
  hasAccessToChannelByPricingPlan: boolean;
};

export class PricingPlansModule extends BaseModule<Props> {
  private getProps = async () => {
    const {
      flowAPI: { httpClient, settings },
      controllerConfig: {
        wixCodeApi: {
          user: { currentUser },
        },
      },
    } = this.params;

    if (currentUser.loggedIn) {
      const pricingPlanService = new PricingPlansAPI(httpClient);
      const channelId = dashify(
        settings.get(settingsParams.channelId),
      ) as string;
      const hasAccessToChannelByPricingPlan =
        await pricingPlanService.hasAccessToChannel(channelId, currentUser.id);

      return {
        hasAccessToChannelByPricingPlan,
      };
    }

    return {
      hasAccessToChannelByPricingPlan: false,
    };
  };

  private handleLogin = async () => {
    const props = await this.getProps();

    this.setProps(props);
  };

  async init(): Promise<Props> {
    const {
      controllerConfig: {
        wixCodeApi: {
          user: { onLogin },
        },
      },
    } = this.params;

    onLogin(this.handleLogin);

    return this.getProps();
  }
}
